<script>
import { authMethods } from '@state/helpers/auth'
import { mapState, mapMutations } from 'vuex'
import eventBus from '@src/event-bus'
import { emailValidation } from '@utils/inputValidation'

export default {
  data() {
    return {
      email: '',
      emailRules: [(value) => emailValidation(value)],
      valid: false,
    }
  },
  computed: {
    ...mapState('auth', {
      lastLoggedInUser: (state) => state.lastLoggedInUser,
    }),
  },
  beforeMount() {
    this.email = this.lastLoggedInUser
  },
  mounted() {
    eventBus.$on('languageChanged', () => {
      this.$refs.form.validate()
    })
  },
  beforeDestroy() {
    eventBus.$off('languageChanged')
  },
  methods: {
    ...authMethods,
    ...mapMutations('auth', ['SET_LAST_LOGGED_IN_USER']),
    submit() {
      if (this.$refs.form.validate()) {
        return this.forgotPassword(this.email)
      }
    },
    redirectToLogin() {
      return this.toggleForgotPassword(false)
    },
  },
}
</script>

<template>
  <div>
    <h1 class="forgotPassword-title"
      >{{ $t('FORGOT_PASSWORD.FORGOT_PASSWORD_TITLE') }}?</h1
    >
    <p class="forgotPassword-subtitle">{{
      $t('FORGOT_PASSWORD.FORGOT_PASSWORD_SUBTITLE')
    }}</p>
    <v-form ref="form" v-model="valid" @submit.prevent="submit">
      <v-text-field
        v-model="email"
        name="email"
        :rules="emailRules"
        :label="$t('EMAIL')"
        outlined
        required
      ></v-text-field>

      <v-btn
        :disabled="!valid"
        class="btn primary-btn forgotPassword-btn"
        color="success"
        type="submit"
        >{{ $t('RESET_PASSWORD') }}</v-btn
      >
      <v-btn class="naked-btn" text small @click="redirectToLogin">{{
        $t('BACK_TO_LOGIN')
      }}</v-btn>
    </v-form>
  </div>
</template>

<style lang="scss" scoped>
@import '@design';

.forgotPassword-title {
  margin-bottom: 20px;
}

.forgotPassword-subtitle {
  margin-bottom: 40px;
}

.forgotPassword-btn {
  margin-top: 85px;
  margin-bottom: 20px;
}
</style>
