<script>
import Auth from '@components/auth-form/auth-form'
import ForgotPassword from '@components/forgot-password/forgot-password'
import Preauth from '@components/preauth-form/preauth-form'
import LanguageSelector from '@components/language-selector/language-selector'
import { authComputed } from '@state/helpers/auth'

export default {
  components: {
    Auth,
    ForgotPassword,
    Preauth,
    LanguageSelector,
  },

  computed: {
    ...authComputed,
  },
}
</script>

<template>
  <div class="v-center">
    <Auth v-if="!!currentUser && shouldShowSecurityCheck" />
    <ForgotPassword v-else-if="isForgotPasswordActive" />
    <Preauth v-else />
    <LanguageSelector />
  </div>
</template>
