<script>
import { authMethods } from '@state/helpers/auth'
import { mapMutations } from 'vuex'
import { emailPattern } from '@utils/regex'
import eventBus from '@src/event-bus'

import {
  emailValidation,
  maxLengthValidation,
  minLengthValidation,
  requiredValidation,
} from '@utils/inputValidation'
export default {
  data() {
    return {
      showPassword: false,
      username: '',
      password: '',
      emailRules: [(value) => emailValidation(value)],
      passwordRules: [
        (value) => requiredValidation(value),
        (value) => maxLengthValidation(value, 50),
        (value) => minLengthValidation(value, 7),
      ],
      valid: false,
    }
  },
  mounted() {
    eventBus.$on('languageChanged', () => {
      this.$refs.form.validate()
    })
  },
  beforeDestroy() {
    eventBus.$off('languageChanged')
  },
  methods: {
    ...authMethods,
    ...mapMutations('auth', ['SET_LAST_LOGGED_IN_USER']),
    submit() {
      if (this.$refs.form.validate()) {
        return this.logIn({
          username: this.username,
          password: this.password,
        })
      }
    },
    onChangeInput(value) {
      if (emailPattern.test(value)) {
        this.SET_LAST_LOGGED_IN_USER(value)
      } else {
        this.SET_LAST_LOGGED_IN_USER('')
      }
    },
    forgotPassword() {
      return this.toggleForgotPassword(true)
    },
    hasUsername() {
      return this.username.length > 0
    },
    hasPassword() {
      return this.password.length > 0
    },
  },
}
</script>

<template>
  <div>
    <h1 class="preauth-title">{{ $t('LOGIN.LOGIN_TITLE') }}</h1>
    <p class="preauth-subtitle">{{ $t('LOGIN.LOGIN_SUBTITLE') }}</p>
    <v-form ref="form" v-model="valid" @submit.prevent="submit">
      <v-row class="row">
        <v-text-field
          v-model="username"
          :rules="emailRules"
          :label="$t('EMAIL')"
          :autofocus="!hasUsername()"
          outlined
          required
          name="username"
          @change="onChangeInput"
        ></v-text-field>
      </v-row>
      <v-row class="row">
        <v-text-field
          v-model="password"
          :append-icon="showPassword ? 'visibility' : 'visibility_off'"
          :rules="passwordRules"
          :type="showPassword ? 'text' : 'password'"
          :label="$t('PASSWORD')"
          :autofocus="hasUsername() && !hasPassword()"
          outlined
          password
          required
          name="password"
          @click:append="showPassword = !showPassword"
        ></v-text-field>
      </v-row>

      <v-btn
        :disabled="!valid"
        class="btn primary-btn preauth-btn center"
        color="success"
        type="submit"
        >{{ $t('LOGIN_TEXT') }}</v-btn
      >
      <v-btn class="naked-btn" text @click="forgotPassword">
        {{ $t('FORGOT_PASSWORD.FORGOT_PASSWORD_TITLE') }}
      </v-btn>
    </v-form>
  </div>
</template>

<style lang="scss" scoped>
@import '@design';

.preauth-title {
  margin-bottom: 20px;
}

.preauth-subtitle {
  margin-bottom: 50px;
  color: $grey;
}

.preauth-btn {
  margin-top: 85px;
  margin-bottom: 20px;
}

.row {
  margin-bottom: 20px;
}
</style>
