var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h1',{staticClass:"auth-title"},[_vm._v(_vm._s(_vm.$t('SECURITY_CHECK.SECURITY_CHECK_TITLE')))]),_c('p',{staticClass:"auth-subtitle"},[_vm._v(" "+_vm._s(_vm.$t('SECURITY_CHECK.SECURITY_CHECK_SUBTITLE'))+" ")]),_c('v-form',{ref:"form",staticClass:"auth-form",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_vm._l((_vm.securityInputs),function(input,index){return _c('div',{key:index,staticClass:"auth-securityInput"},[_c('v-text-field',{key:"{input.name}",ref:"securityInputs",refInFor:true,attrs:{"autofocus":index === 0,"outlined":"","required":"","rules":_vm.securityCodeRules,"hide-details":"","single-line":"","type":"number"},on:{"paste":(event) => _vm.handlePaste(event, input.name),"keyup":(event) => _vm.handleKeyUp(event, input.name),"keydown":(event) => _vm.handleKeyDown(event, input.name)},model:{value:(input.value),callback:function ($$v) {_vm.$set(input, "value", $$v)},expression:"input.value"}})],1)}),_c('p',{class:_vm.hasSecurityCodeErrors
          ? 'auth-error-message auth-error-message--active'
          : 'auth-error-message'},[_vm._v(_vm._s(_vm.$t('VALIDATION_ERRORS.EXACTLY_ONE_DIGIT')))]),_c('p',{class:_vm.hasPastedLengthError
          ? 'auth-error-message auth-error-message--active'
          : 'auth-error-message'},[_vm._v(_vm._s(_vm.$t('VALIDATION_ERRORS.CODE_TOO_MANY_CHARACTERS')))]),_c('p',{class:_vm.hasPastedNumberError
          ? 'auth-error-message auth-error-message--active'
          : 'auth-error-message'},[_vm._v(_vm._s(_vm.$t('VALIDATION_ERRORS.CODE_NOT_NUMBERS')))]),_c('v-btn',{ref:"submitButton",staticClass:"btn primary-btn auth-btn center",attrs:{"disabled":!_vm.valid || _vm.hasSecurityCodeErrors,"type":"submit"}},[_vm._v(_vm._s(_vm.$t('LOGIN_TEXT')))]),_c('v-btn',{staticClass:"naked-btn",attrs:{"text":""},on:{"click":_vm.redirectToLogin}},[_vm._v(" "+_vm._s(_vm.$t('BACK_TO_LOGIN'))+" ")])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }