<script>
import Layout from '@layouts/login/login'
import Authentication from '@components/authentication-wrapper/authentication-wrapper'
import i18n from '@src/i18n'
import appConfig from '@src/app.config'

export default {
  page: {
    title: i18n.t('LOG_IN'),
    meta: [{ name: 'description', content: `Log in to ${appConfig.title}` }],
  },
  components: { Layout, Authentication },
}
</script>

<template>
  <Layout>
    <Authentication />
  </Layout>
</template>
