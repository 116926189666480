<script>
import { authMethods } from '@state/helpers/auth'
import { requiredValidation } from '@utils/inputValidation'
import { range } from 'lodash'
export default {
  data: function () {
    return {
      securityInputs: [
        {
          name: `securityInput0`,
          value: '',
        },
        {
          name: `securityInput1`,
          value: '',
        },
        {
          name: `securityInput2`,
          value: '',
        },
        {
          name: `securityInput3`,
          value: '',
        },
        {
          name: `securityInput4`,
          value: '',
        },
        {
          name: `securityInput5`,
          value: '',
        },
      ],
      securityCodeRules: [(value) => requiredValidation(value)],

      valid: false,
      hasSecurityCodeErrors: false,
      hasPastedLengthError: false,
      hasPastedNumberError: false,
      ctrlDown: false,
      isPasting: false,
      keyCodes: {
        ctrl: 17,
        cmd: 91,
        backspace: 8,
        enter: 13,
      },
    }
  },
  methods: {
    ...authMethods,

    handleSecurityCode() {
      const code = this.securityInputs.map((e) => e.value).join('')
      return this.submitSecurityCode(code)
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.handleSecurityCode()
      }
    },
    redirectToLogin() {
      return this.resetPreauthenticatedUser()
    },

    autofocusNextInput(inputName) {
      const currentInputIndex = inputName.slice(-1)
      const currentInputValue =
        this.$refs.securityInputs[currentInputIndex].value

      if (+currentInputIndex < 5 && currentInputValue) {
        this.$refs.securityInputs[
          (currentInputIndex, +currentInputIndex + 1)
        ].focus()
      }
    },

    autofocusPrevInput(inputName) {
      const currentInputIndex = inputName.slice(-1)

      if (currentInputIndex > 0) {
        this.$refs.securityInputs[
          (currentInputIndex, +currentInputIndex - 1)
        ].focus()
      }
    },

    handleKeyUp(event, inputName) {
      const { keyCode } = event
      const { ctrl, cmd, backspace } = this.keyCodes

      if (this.securityInputs.some((entry) => !entry.value)) {
        this.autofocusNextInput(inputName)
      }

      if (keyCode === ctrl || keyCode === cmd) this.ctrlDown = false

      if (keyCode === backspace) {
        this.autofocusPrevInput(inputName)
      }
    },

    handleKeyDown(event, inputName) {
      const { keyCode } = event
      const { ctrl, cmd } = this.keyCodes
      if (keyCode === ctrl || keyCode === cmd) this.ctrlDown = true

      const inputValue = this.$refs.securityInputs[inputName.slice(-1)].value
      if (!this.ctrlDown) {
        this.handleErrors(keyCode, inputValue)
      }
    },

    handleErrors(keyCode, inputValue) {
      this.hasPastedNumberError = false
      this.hasPastedLengthError = false
      if (!inputValue && !this.keyCodeCheck(keyCode)) {
        this.hasSecurityCodeErrors = true
        event.preventDefault()
      } else if (
        inputValue &&
        keyCode !== this.keyCodes.backspace &&
        keyCode !== this.keyCodes.enter
      ) {
        event.preventDefault()
      } else {
        this.hasSecurityCodeErrors = false
      }
    },

    keyCodeCheck(keycode) {
      const isKeypad = range(96, 106).includes(keycode)
      const normalizedKeyCode = isKeypad ? keycode - 48 : keycode
      const isDigit = range(48, 58).includes(normalizedKeyCode)
      return (
        isDigit &&
        keycode !== this.keyCodes.backspace &&
        keycode !== this.keyCodes.enter
      )
    },

    handlePaste(event, inputName) {
      event.stopPropagation()
      event.preventDefault()

      // reset all errors
      this.hasPastedNumberError = false
      this.hasPastedLengthError = false
      this.hasSecurityCodeErrors = false

      const clipboardData = event.clipboardData || window.clipboardData
      const pastedValue = clipboardData.getData('Text')
      if (pastedValue.length === 6) {
        const pastedValueCheckFailed = [...pastedValue].some((entry) => {
          return isNaN(entry)
        })

        if (pastedValueCheckFailed) {
          this.hasPastedNumberError = true
        }

        if (!pastedValueCheckFailed) {
          this.hasSecurityCodeErrors = false
          ;[...pastedValue].forEach((entry, i) => {
            this.securityInputs[i].value = entry
          })
          this.ctrlDown = false
        }
      } else {
        this.hasPastedLengthError = true
      }
    },
  },
}
</script>

<template>
  <div>
    <h1 class="auth-title">{{ $t('SECURITY_CHECK.SECURITY_CHECK_TITLE') }}</h1>
    <p class="auth-subtitle">
      {{ $t('SECURITY_CHECK.SECURITY_CHECK_SUBTITLE') }}
    </p>
    <v-form
      ref="form"
      v-model="valid"
      class="auth-form"
      @submit.prevent="submit"
    >
      <div
        v-for="(input, index) in securityInputs"
        :key="index"
        class="auth-securityInput"
      >
        <v-text-field
          key="{input.name}"
          ref="securityInputs"
          v-model="input.value"
          :autofocus="index === 0"
          outlined
          required
          :rules="securityCodeRules"
          hide-details
          single-line
          type="number"
          @paste="(event) => handlePaste(event, input.name)"
          @keyup="(event) => handleKeyUp(event, input.name)"
          @keydown="(event) => handleKeyDown(event, input.name)"
        />
      </div>
      <p
        :class="
          hasSecurityCodeErrors
            ? 'auth-error-message auth-error-message--active'
            : 'auth-error-message'
        "
        >{{ $t('VALIDATION_ERRORS.EXACTLY_ONE_DIGIT') }}</p
      >

      <!-- TODO Define/Implement messages better -->
      <p
        :class="
          hasPastedLengthError
            ? 'auth-error-message auth-error-message--active'
            : 'auth-error-message'
        "
        >{{ $t('VALIDATION_ERRORS.CODE_TOO_MANY_CHARACTERS') }}</p
      >

      <p
        :class="
          hasPastedNumberError
            ? 'auth-error-message auth-error-message--active'
            : 'auth-error-message'
        "
        >{{ $t('VALIDATION_ERRORS.CODE_NOT_NUMBERS') }}</p
      >
      <v-btn
        ref="submitButton"
        class="btn primary-btn auth-btn center"
        :disabled="!valid || hasSecurityCodeErrors"
        type="submit"
        >{{ $t('LOGIN_TEXT') }}</v-btn
      >
      <v-btn class="naked-btn" text @click="redirectToLogin">
        {{ $t('BACK_TO_LOGIN') }}
      </v-btn>
    </v-form>
  </div>
</template>

<style lang="scss" scoped>
@import '@design';

.auth-form {
  position: relative;
}

.auth-title {
  margin-bottom: 20px;
}

.auth-subtitle {
  margin-bottom: 50px;
  color: $grey;
}

.auth-btn {
  margin-top: 115px;
  margin-bottom: 20px;
}

.auth-error-message {
  position: absolute;
  width: 100%;
  height: 0;
  margin-top: 20px;
  overflow: hidden;
  color: $alert-error;
  text-align: center;
  transition: height 0.1s;

  // Error and transition imitates vuetify styles
  &--active {
    height: 16px;
  }
}

.auth-securityInput {
  display: inline-block;
  width: calc((100% / 6) - 10px);
  margin-right: 10px;

  &:last-of-type {
    margin-right: 0;
  }

  .v-input::v-deep {
    input {
      text-align: center;
    }
  }
}
</style>
